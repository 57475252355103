* {
    box-sizing: border-box;
}

:root {
    --color-bg: #2C2C32;
    --color-active: #0f7dc6;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('./img/bg.jpg');
}

header {
    background-color: var(--color-bg);
    padding: 2rem 0;
    text-align: center;
}

header>a {
    color: white;
    text-decoration: none;
    padding: 0 1rem;
}

header>a:hover {
    text-decoration: underline;
}

h1 {
    text-align: center;
    margin-top: 0;
}

.container {
    max-width: 900px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0 auto;
}
.Sensor2 {
    box-shadow: 0 4px 28px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 10px;
}
main {
    min-height: calc(100vh - 85px - 22px);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

main a {
    text-decoration: none;
    color: var(--color-bg);
}

main a:hover {
    text-decoration: underline;
}

.active {
    color: var(--color-active);
    cursor: default;
}
@keyframes blink {
    0% {
        box-shadow: 0 0 20px #EC7A44;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0 0 20px #EC7A44;
    }
}
@-webkit-keyframes blink {
    0% {
        box-shadow: 0 0 20px #EC7A44;
    }
    50% {
        box-shadow: 0 0 0;
    }
    100% {
        box-shadow: 0 0 20px #EC7A44;
    }
}
.blink {
    -webkit-animation: blink 1.0s linear infinite;
    -moz-animation: blink 1.0s linear infinite;
    -ms-animation: blink 1.0s linear infinite;
    -o-animation: blink 1.0s linear infinite;
    animation: blink 1.0s linear infinite;
}